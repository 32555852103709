(function ($) {

    var promise;
    var dydaId = 0;
    var events = [];

    // create dictionary of cookie names and values
    var cookies = {};
    $.each(document.cookie.split(';'), function (i, v) {
        var match = /^(\w+)=([^;]*)/g.exec($.trim(v));
        if (match !== null) {
            cookies[match[1]] = match[2];
        }
    });

    $.fn.dyda = function (options) {

        var $this = this;
        var watchers = {};
        var documentVisible = document.visibilityState !== "hidden";

        $(document).on("visibilitychange", function () {
            documentVisible = document.visibilityState !== "hidden";
            if (documentVisible) {
                // document is visible so start timing on visible elements
                $.each(watchers, function (elementId, watcher) {
                    if (watcher.visible)
                        watcher.startTimer();
                });
            } else {
                // document is hidden so stop timing on visible elements
                $.each(watchers, function (elementId, watcher) {
                    if (watcher.visible)
                        watcher.stopTimer();
                });
            }
        });

        // this is the easiest way to have default options
        var settings = $.extend({}, $.fn.dyda.defaults, options);

        // root is the browser viewport / screen
        var observer = new IntersectionObserver(function (entries) {
            for (i = 0; i < entries.length; i++) {
                var entry = entries[i];
                var element = entry.target;
                //var intersecting = entry.isIntersecting;
                var ratio = entry.intersectionRatio;

                var elementId = $(element).data(settings.namespace + "-id");

                var elementVisible = ratio >= 0.5;

                var watcher = watchers[elementId];

                if (elementVisible) {
                    if (!watcher.visible) {
                        watcher.visible = true;
                        if (documentVisible)
                            watcher.startTimer();
                    }
                } else {
                    if (watcher.visible) {
                        watcher.visible = false;
                        watcher.stopTimer();
                    }
                }
            }
        }, { threshold: [0, 0.5, 1] });

        events.push = function (event) {
            Array.prototype.push.apply(this, arguments);

            if (!promise) {
                promise = $.ajax({
                    type: "POST",
                    url: settings.applicationPath + "api/data/start",
                    data: JSON.stringify({
                        timestamp: new Date(),
                        url: document.URL,
                        referrer: document.referrer,
                        userAgent: navigator.userAgent,
                        TID: cookies["TID"],
                        RID: cookies["RID"]
                    }),
                    contentType: "application/json; charset=utf-8",
                    dataType: "json"
                })
            }

            promise.done(function (code) {
                var eventsToSend = [];

                while (events.length) {
                    var event = events.shift();
                    event.code = code;
                    event.data = JSON.stringify(event.data);
                    eventsToSend.push(event);
                }

                if (eventsToSend.length) {
                    $.ajax({
                        type: "POST",
                        url: settings.applicationPath + "api/data/event",
                        data: JSON.stringify(eventsToSend),
                        contentType: "application/json; charset=utf-8",
                        dataType: "json"
                    });
                }
            });
        };

        $this.each(function (index, element) {

            var elementId = $(element).data(settings.namespace + "-id");
            if (!elementId) {
                elementId = ++dydaId;
                $(element).data(settings.namespace + "-id", elementId);
            }
            var id = $(element).attr("id");

            events.push({
                elementId: elementId,
                category: settings.category,
                name: "Load",
                data: $.extend({ id: id }, settings.data),
                timestamp: new Date()
            });

            // add to list of elements to watch
            watchers[elementId] = {
                visible: false,
                startTimer: function () {
                    if (!watchers[elementId].timer) {
                        watchers[elementId].timer = setTimeout(function () {
                            // if element is still visible after one second then count this as a view
                            observer.unobserve(element);
                            delete watchers[elementId];

                            events.push({
                                elementId: elementId,
                                category: settings.category,
                                name: "View",
                                data: { id: id },
                                timestamp: new Date()
                            });
                        }, 1000);
                    }
                },
                stopTimer: function () {
                    if (watchers[elementId].timer) {
                        clearTimeout(watchers[elementId].timer);
                        delete watchers[elementId].timer;
                    }
                }
            };

            // watch for intersection changes
            observer.observe(element);

            // register click events within this element
            $(element).on("click", "a", function () {
                var id = $(this).attr("id");
                var href = $(this).prop("href");
                
                events.push({
                    elementId: elementId,
                    category: settings.category,
                    name: "Click",
                    data: $.extend({ id: id, href: href }, $(this).data(settings.namespace)),
                    timestamp: new Date()
                });
            });

            // register form submits within this element
            $(element).on("submit", "form", function () {
                var id = $(document.activeElement).attr("id");
                var action = $(this).prop("action");

                events.push({
                    elementId: elementId,
                    category: settings.category,
                    name: "Submit",
                    data: $.extend({ id: id, action: action }, $(this).data(settings.namespace)),
                    timestamp: new Date()
                });
            });
        });
    }

    $.fn.dyda.defaults = {
        namespace: "dyda",
        applicationPath: "/"
    };

})(jQuery);