(function ($) {

    $(function () {
        $(".show-marketing-desc").click(function (e) {
            $(".marketing-desc").delay(400).show();
        });

        $(".hide-marketing-desc").click(function (e) {
            $(".marketing-desc").hide();
        });

        // newsletter validation
        $("#btnSubscribe").click(function (e) {
            e.preventDefault();
            var _this = $(this);
            var _form = _this.closest("form");

            trySubscribe(_form);
        });
       
    });

    var trySubscribe = function (form) {
        $(".submit-loader-wrap").show();
        $('#SignupCountry').removeAttr("disabled");
        $('#btnSubscribe').prop('disabled', true);
        $.ajax({
            type: "POST",
            url: $(form).attr("action"),
            data: form.serialize(),
            success: function (data, status, xhr) {
                $(".submit-loader-wrap").hide();

                var isSuccessful = (data["success"]);
                if (isSuccessful) {
                    $("#NLmain").hide();
                    $(".successMessage").prepend("Thanks for signing up! We’ve sent you an email to confirm your address.").addClass("show");

                    if (data["inboxLink"]) {
                        $(".inboxLink").html("<a href='" + data["inboxLink"]+"'>Check your inbox.</a>");
                    }
                }
                else {
                    var errors = data["errors"];
                    displayValidationErrors(errors);
                }
                $('#btnSubscribe').prop('disabled', false);
            },
            error: function (xhr, status, err) {
                $(".submit-loader-wrap").hide();

                $("#NLmain").hide();
                $(".successMessage").text("There's been an error while sending your information. Please try again later.").addClass("show error");
                $('#btnSubscribe').prop('disabled', false);
            }
        });
    };

    var displayValidationErrors = function (modelStateErrors) {
        $("#NLsignup span[data-valmsg-for]").removeClass("show");
        $("#NLsignup .error").removeClass("error");
        for (var i = 0; i < modelStateErrors.length; i++) {
            $("span[data-valmsg-for='" + modelStateErrors[i].key + "']").text(modelStateErrors[i].errors[0]).addClass("show");
            $("#" + modelStateErrors[i].key).addClass("error");
        }
    };

}) (jQuery);