(function ($) {

    $(function () {

        ssm.ready();
        //desktop navigation
        if ($(window).width() > 1000) {
            function hideMenu(el) {
                $(".item-hover").removeClass("item-hover");
                $("body").removeClass("no-scroll-blockout");
                this.removeEventListener("click", hideMenu);
            }

            $(".has-links").on("click", function (e) {
                const el = document.getElementById("menu-block-out");
                var $thisItem = $(this).parents(".menu-item");
                el.addEventListener("click", hideMenu, el);
                e.stopPropagation();
                e.preventDefault();

                //Open this menu  
                if (!$thisItem.hasClass("item-hover")) {
                    $(".item-hover").removeClass("item-hover");
                    $thisItem.addClass("item-hover");
                    $("body").addClass("no-scroll-blockout");
                }

                //Close this menu  
                else {
                    $thisItem.removeClass("item-hover");
                    $("body").removeClass("no-scroll-blockout");
                    //Remove event listener to close  
                    el.removeEventListener("click", hideMenu);

                }
            });




            //Hide title attribute on hover and reinstate on mouseout 
            $('.menu-item [title]').mouseover(function () {
                var $this = $(this);
                $this.data('title', $this.attr('title'));
                $this.attr('title', '');
            }).mouseout(function () {
                var $this = $(this);
                $this.attr('title', $this.data('title'));
            });
        }
        //Mobile navigation
        else {

            //Open main navigation
            $(".nav-button").on("click", function (e) {
                e.stopPropagation();
                e.preventDefault();
                $(window).scrollTop(0);
                $("body").toggleClass("no-scroll-blockout");
                $(".header-menu").toggleClass("menu-in");
                $(".hamburger-menu").toggleClass("close-menu");
            });
            //Hide/show menu items
            $(".has-links").on("click", function (e) {
                var $thisItem = $(this).parents(".menu-item");
                e.stopPropagation();
                e.preventDefault();

                //Open this menu and hide login/country links
                if (!$thisItem.hasClass("item-hover")) {
                    $thisItem.addClass("item-hover");
                }

                //Close this menu and show login/country links
                else {
                    $thisItem.removeClass("item-hover");
                }
            });

        }

        // Account navigation Members Area
        $('#account-menu-header').on('click', '.sub-nav-toggle', function (evt) {
            var $thisItem = $(this).parents("li");

            //Open this item
            $thisItem.toggleClass('active-item');
            //Close other items
            $("#account-menu-header li").not($thisItem).removeClass("active-item");
        });

        // Account navigation Members Area
        $('#account-menu').on('click', '.sub-nav-toggle', function (evt) {
            var $thisItem = $(this).parents("li");

            //Open this item
            $thisItem.toggleClass('active-item');
            //Close other items
            $("#account-menu-header li").not($thisItem).removeClass("active-item");
        });


        // Show and hide country picker
        $('.current-cobrand-wrap').on('click', function (evt) {
            evt.preventDefault();
            $('.cobrand-selector').addClass('active-nav');
            $('.nav-wrap').removeClass('active-nav');
        });

        $('.close-cobrands-e').on('click', function (evt) {
            evt.preventDefault();
            $('.cobrand-selector').removeClass('active-nav');
        });

        // Back to top link
        $(window).scroll(function () {
            if ($(this).scrollTop() !== 0) {
                $('.to-top').fadeIn();
            } else {
                $('.to-top').fadeOut();
            }
        });

        // suggested cobrand functionality
        if ($("#suggested-cobrand-notifier").length) {
            // create dictionary of cookie names and values
            var cookies = {};
            $.each(document.cookie.split(';'), function (i, v) {
                var match = /^(\w+)=([^;]*)/g.exec($.trim(v));
                if (match !== null) {
                    cookies[match[1]] = match[2];
                }
            });

            // only display once per session
            if (!(cookies['suggestedCobrand'] == '0')) {
                // add cobrand bar to top of page
                $("#suggested-cobrand-notifier").prependTo('body').show();

                // don't show again if use has clicked on one of the notifier links
                $("#suggested-cobrand-notifier a").on('click', function () {
                    document.cookie = 'suggestedCobrand=0; path=/';
                });

                // hide on close button click
                $("#suggested-cobrand-close-wrap").on('click', function (evt) {
                    evt.preventDefault();
                    $("#suggested-cobrand-notifier").slideUp("slow");
                });

                var eyeballs = (cookies['suggestedCobrand'] || '10') - 1;

                // set session cookie
                document.cookie = 'suggestedCobrand=' + eyeballs + '; path=/';
            }
        }

    });

})(jQuery);